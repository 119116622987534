import { Link } from "react-router-dom";

export default function CompanyInfo({ version }) {
  return (
    <div className="max-w-[1440px] mx-auto px-4 md:px-[88px]">
      {version === "how-to" && (
        <>
          <h1 className="bold-header text-4xl mt-[40px] mb-10">HOW TO:</h1>
          <p className="bold-header text-2xl mb-4">
            How to buy Helvetica NFTs?
          </p>
          <img
            className="mb-8 h-[600px]"
            src={"/static/buy-example.png"}
            alt={"Buy Now Example"}
          />
          <p className="bold-header underline text-xl">
            1. Connect an existing wallet.
          </p>
          <p className="text-xl mb-4">
            Click on the ‘Connect Wallet’ button in the top right. For this
            example we will use MetaMask as the most common wallet in use.
          </p>
          <p className="text-xl mb-4">
            By default, MetaMask is configured for the Ethereum network. But for
            Helvetica NFTs, your wallet should be configured for the Avalanche
            network. You can switch to Avalanche by clicking on the ‘Ethereum
            Main Network’ tab; a drop-down menu will appear, and select the
            ‘Avalanche Network’ on the list. Don’t worry if it is not there, you
            can always “Add a network”.
          </p>
          <p className="text-xl mb-4">
            Now, your MetaMask wallet is configured for the Avalanche network.
            Click on the ‘Next’ button, and connect your wallet with the
            KnownUnknown NFT marketplace.
          </p>
          <p className="bold-header underline text-xl">
            2. Create a new wallet.
          </p>
          <p className="text-xl mb-4">
            If you are new to NFTs we recommend opening a paper.xyz wallet at
            the check out where it states ‘Create a wallet for me’. You can
            create, or re-connect to an existing Paper wallet that can be used
            for any future purchases with Paper.
          </p>
          <p className="bold-header text-2xl">
            What currency do I use to pay for the NFT?
          </p>
          <p className="text-xl mb-4">
            We offer several ways to pay for your Helvetica NFT. The easiest
            will be using a bank card but you can also pay using crypto
            currencies. When you are ready, click the ‘Buy now’ button on the
            artist page, and a check out window will appear.
          </p>
          <p className="bold-header underline text-xl">
            1. Payment using bank card.
          </p>
          <p className="text-xl mb-4">
            This is where you will see the option to use your bank card. Tap
            ‘Enter Card Information’ and Paper will first ask you to verify your
            email. Paper will then send you an email to log into Paper and once
            you have logged in the same check out page will refresh to a
            familiar bank card check out, asking for the usual details such as;
            Cardholder name, Card number etc. ‘Review’ your information, accept
            Paper’s ‘Terms of Service’ and ‘Pay now’. Your purchase will show as
            ‘Confirmed’ and the NFT will be sent to the wallet that you
            connected to (e.g. MetaMask, or Coinbase etc). Also look for an
            email confirmation and receipt from Paper, in your inbox.
          </p>
          <p className="bold-header underline text-xl">
            2. Payment using crypto currency.
          </p>
          <p className="text-xl mb-4">
            Please ensure your wallet has the correct crypto currency and also
            enough funds to cover the cost of the NFT. At the check out, click
            ‘WalletConnect” that supports many wallets including MetaMask.
            Alternatively click ‘Coinbase Wallet’.
          </p>
          <p className="text-xl mb-4">
            Here you will ‘Select Payment Method’. In addition to the bank card
            option outlined above, you can purchase using USDC.e on Avalanche,
            or ETH on Ethereum.
          </p>
          <p className="bold-header text-2xl">What is USDC.e vs. USDC?</p>
          <p className="text-xl mb-4">
            USDC.e is not the same as USDC and we advise the following;
          </p>
          <p className="bold-header text-xl">Please note:</p>
          <ul className="list-disc list-inside text-xl mb-4">
            <li>USDC.e on Avalanche is a “bridged form of Ethereum USDC”</li>
            <li>
              Use USDC.e only if you hold sufficient USDC.e in your wallet
            </li>
            <li>
              You cannot purchase Helvetica NFTs with traditional USDC (e.g. via
              Coinbase), it will alert you that you have insufficient funds.{" "}
            </li>
            <li>Coinbase Exchange account does not support USDC.e</li>
            <li>
              If connecting your Coinbase wallet we recommend using bank card,
              or ETH
            </li>
          </ul>
          <p className="bold-header text-xl mb-[40px]">
            For additional information please see our{" "}
            <Link className="text-blue-600" to={"/FAQs"}>
              FAQs
            </Link>
            .
          </p>
        </>
      )}
      {version === "about" && (
        <>
          <h1 className="bold-header text-4xl mt-[40px] mb-10">ABOUT:</h1>
          <p className="bold-header text-3xl mb-4">KnownUnknown</p>
          <p className="bold-header text-xl mb-4">
            An emerging creative platform for the coming age. We are a growing
            global network that challenges outdated models by connecting brands
            with expertly curated creative talent.
          </p>
          <p className="bold-header text-xl mb-4">
            Fueled by the most exciting and diverse talent on the planet, our
            purpose is to inspire a community through collaboration,
            connections, shared rewards and innovation.
          </p>
          <p className="mb-4 text-xl">
            Founded by world-renowned design leader James Sommerville and his
            unique perspective working alongside numerous brand and business
            owners as ATTIK co-founder and VP Global Design at The Coca-Cola
            Company.
          </p>
          <p className="mb-4 text-xl">
            We hope that KnownUnknown can become the destination for creatives
            to connect through mentoring and coaching, hosting and inviting
            collaborations, sharing insights, attending virtual and physical
            events. We invite creatives across all areas of the industry, both
            traditional and emerging Web3.0.
          </p>
          <p className="mb-8 text-xl">
            We are just at the beginning of our journey and we appreciate your
            support.
          </p>
          <p className="bold-header text-3xl mb-4">Helvetica The NFT.</p>
          <p className="bold-header mb-4 text-xl">
            Helvetica is one of the most beloved, and polarizing, typefaces in
            the world. It has been used by designers and brands for decades.
            Now, it is being used to develop Monotype’s first ever digital art
            collection.
          </p>
          <p className="mb-8 text-xl">
            More than two dozen prolific artists have come together to create a
            unique digital collection celebrating Helvetica and the role of
            typography in culture. The collection explores several ideas and
            mediums, unique and personal to each artist, all converted to
            digital formats and available as NFTs.
          </p>
          <p className="bold-header text-3xl mb-4">Project Collaborators</p>
          <p className="mb-[40px] flex flex-col text-xl">
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={"https://knownunknown.com"}
            >
              https://knownunknown.com
            </a>
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={"https://www.monotype.com"}
            >
              https://www.monotype.com
            </a>
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={"https://www.avalabs.org"}
            >
              https://www.avalabs.org
            </a>
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={"https://thirdweb.com"}
            >
              https://thirdweb.com
            </a>
          </p>
        </>
      )}
      {version === "FAQs" && (
        <>
          <h1 className="bold-header text-4xl mt-[40px] mb-10">FAQ:</h1>
          <p className="bold-header text-2xl mb-4">
            How do I buy Helvetica NFTs?
          </p>
          <p className="bold-header underline text-xl">
            1. If you have purchased NFTs before and you have an existing
            wallet.
          </p>
          <p className="text-xl mb-4">
            From the KnownUnknown NFT marketplace click on the ‘Connect Wallet’
            button in the top right. For this example we will use MetaMask as
            the most common wallet in use.
          </p>
          <p className="text-xl mb-4">
            By default, MetaMask is configured for the Ethereum network. But for
            Helvetica NFTs, your wallet should be configured for the Avalanche
            network. You can switch to Avalanche by clicking on the ‘Ethereum
            Main Network’ tab; a drop-down menu will appear, and select the
            ‘Avalanche Network’ on the list. Don’t worry if it is not there, you
            can always “Add a network”.
          </p>
          <p className="text-xl mb-4">
            Now, your MetaMask wallet is configured for the Avalanche network.
            Click on the ‘Next’ button, and connect your wallet with the
            KnownUnknown NFT marketplace.
          </p>
          <p className="bold-header underline text-xl">
            2. If this is your first NFT purchase and you do not have an
            existing wallet.
          </p>
          <p className="text-xl mb-4">
            If you are new to NFTs we recommend opening a paper.xyz wallet at
            the check out where it states ‘Create a wallet for me’ alongside the
            Paper logo. You can create, or re-connect to an existing Paper
            wallet that can be used for any future purchases with Paper.
          </p>
          <p className="bold-header text-2xl">
            What currency do I use to pay for the NFT?
          </p>
          <p className="text-xl mb-4">
            For ease, we offer several ways to pay for your Helvetica NFT. The
            easiest will be using a bank card but you can also pay using crypto
            currencies. When you are ready, click the ‘Buy now’ button on the
            artist page, and a check out window will appear.
          </p>
          <p className="bold-header underline text-xl">
            1. Payment using bank card.
          </p>
          <p className="text-xl mb-4">
            This is where you will see the option to use your bank card. Tap
            ‘Enter Card Information’ and Paper will first ask you to verify your
            email. Paper will then send you an email to log into Paper and once
            you have logged in the same check out page will refresh to a
            familiar bank card check out, asking for the usual details such as;
            Cardholder name, Card number etc. ‘Review’ your information, accept
            Paper’s ‘Terms of Service’ and ‘Pay now’. Your purchase will show as
            ‘Confirmed’ and the NFT will be sent to the wallet that you
            connected to (e.g. MetaMask, or Coinbase etc). Also look for an
            email confirmation and receipt from Paper, in your inbox.
          </p>
          <p className="bold-header underline text-xl">
            2. Payment using crypto currency.
          </p>
          <p className="text-xl mb-4">
            Please ensure your wallet has the correct crypto currency and also
            enough funds to cover the cost of the NFT. At the check out, click
            ‘WalletConnect” that supports many wallets including MetaMask.
            Alternatively click ‘Coinbase Wallet’.
          </p>
          <p className="text-xl mb-4">
            Here you will ‘Select Payment Method’. In addition to the bank card
            option outlined above, you can purchase using USDC.e on Avalanche,
            or ETH on Ethereum.
          </p>
          <p className="bold-header text-2xl">What is USDC.e vs. USDC?</p>
          <p className="text-xl mb-4">
            USDC.e is not the same as USDC and we advise the following;
          </p>
          <p className="bold-header text-xl">Please note:</p>
          <ul className="list-disc list-inside text-xl mb-4">
            <li>USDC.e on Avalanche is a “bridged form of Ethereum USDC”</li>
            <li>
              Use USDC.e only if you hold sufficient USDC.e in your wallet
            </li>
            <li>
              You cannot purchase Helvetica NFTs with traditional USDC (e.g. via
              Coinbase), it will alert you that you have insufficient funds.{" "}
            </li>
            <li>Coinbase Exchange account does not support USDC.e</li>
            <li>
              If connecting your Coinbase wallet we recommend using bank card,
              or ETH
            </li>
          </ul>
          <p className="bold-header text-2xl">
            Can I buy more than one Helvetica NFT?
          </p>
          <p className="text-xl mb-4">
            Yes, we are not restricting the purchase quantity.
          </p>
          <p className="bold-header text-2xl">
            Where can I see my Helvetica NFT?
          </p>
          <p className="text-xl mb-4">
            After you have successfully purchased a Helvetica NFT, you can see
            it on the blockchain (Avalanche Explorer) and/or in your Metamask
            (mobile app) wallet?
          </p>
          <p className="bold-header text-2xl">Where do I view my NFT?</p>
          <div className="ml-8">
            <p className="bold-header underline text-xl">
              1. View on the Avalanche Explorer blockchain?
            </p>
            <p className="text-xl mb-4">
              Avalanche Explorer is called{" "}
              <a
                className="text-blue-600"
                rel="noreferrer"
                target="_blank"
                href={"https://snowtrace.io/"}
              >
                https://snowtrace.io/
              </a>{" "}
              which is an analytics tool that enables people to search the
              Avalanche blockchain for transactions, addresses, tokens, prices
              and other platform activities. On this platform you will be able
              to view your Helvetica NFT.
            </p>
            <p className="text-xl mb-4">
              Open the Metamask wallet via your desktop extension and ‘Account
              Options’ (three-dots) in top right. Then click the ‘View Account
              in Explorer’ tab that will take you to a new{" "}
              <a
                className="text-blue-600"
                rel="noreferrer"
                target="_blank"
                href={"https://snowtrace.io/"}
              >
                https://snowtrace.io/
              </a>{" "}
              page, showing assets in your wallet on the Avalanche Explorer
              dashboard. Click the ‘Token’ section and a dropdown menu will
              appear containing all assets in your wallet. It confirms that you
              are the owner of this NFT. Click on the name of the NFT, and a new
              dashboard page with the details of the NFT will open. Details like
              Txn Hash, from which wallet the NFT was generated, and in which
              wallet address the NFT was deposited.
            </p>
            <p className="text-xl mb-4">
              Unfortunately, you will not see these NFTs on the Metamask browser
              extension, but you can view them on Metamask wallet mobile app, by
              using the contract address and the TokenID of NFT copied from
              Snowtrace. The following provides guidance on how to do this.
            </p>
            <p className="bold-header underline text-xl">
              2. View on MetaMask mobile app?
            </p>
            <p className="text-xl mb-4">
              Immediately after purchasing the Helvetica NFT, there appears to
              be no NFT in your MetaMask wallet, but the NFT is still on the
              blockchain. This section informs how to import it to your MetaMask
              (mobile only) app by extracting the details on your NFT from
              Avalanche Explorer{" "}
              <a
                className="text-blue-600"
                rel="noreferrer"
                target="_blank"
                href={"https://snowtrace.io/"}
              >
                https://snowtrace.io/
              </a>{" "}
            </p>
            <p className="text-xl mb-4">
              For this exercise we assume that your wallet is configured for the
              Avalanche network, and you have the same wallet address as the one
              you used during the purchase on the KnownUnknown NFT marketplace.
              Otherwise, you will have to import your wallet into MetaMask using
              a private key and configure it for the Avalanche blockchain
              network.{" "}
              <span className="bold-header">
                Remember, we will never ask for your private key or seed phrase.
                It is your responsibility to keep your wallet safe.
              </span>
            </p>
            <p className="text-xl mb-4">
              To make NFTs appear in your Metamask mobile wallet. Open the
              MetaMask app and tap on the hamburger menu in the top left corner.
              In the menu, tap on the view on Snowtrace button.{" "}
            </p>
            <p className="text-xl mb-4">
              Once on your Snowtrace dashboard, click the ‘Token’ section and a
              dropdown menu will appear containing all assets in your wallet.
              Ensure you copy the Address (‘Interacted With (To)’) and also the
              TokenID of your NFT (‘Txn Hash’). Note: A Transaction Hash (Txn
              Hash), also known as Transaction ID (TxID), is the unique address
              of a transaction in a blockchain that acts as a record or proof
              that the transaction has taken place.
            </p>
            <p className="text-xl mb-4">
              Back in MetaMask, click the NFT tab and ‘Import NFT’ button and
              paste both the Address and TokenID of your NFT into the fields.
              MetaMask could take a few minutes to fetch the Metadata and NFT
              preview from the source depending on the network congestion or
              traffic. If the NFT preview is unavailable, try refreshing the
              Metadata of NFT by holding the NFT and selecting the ‘Refresh
              Metadata’ button.
            </p>
            <p className="bold-header underline text-xl">
              3. View on Coinbase?
            </p>
            <p className="text-xl mb-4">
              Open the browser in your Coinbase wallet. Then paste
              “nft.coinbase.com” in the search and choose your username and also
              verify your email address. Once set-up, visit your Coinbase NFT
              profile and your NFT should appear in the ‘Owned’, or ‘Hidden’
              section.
            </p>
            <p className="bold-header underline text-xl">
              4. View in your new Paper.xyz wallet?
            </p>
            <p className="text-xl mb-4">
              If you open a Paper wallet as part of the process, you can view
              your Helvetica NFT at any time by going to{" "}
              <a
                className="text-blue-600"
                rel="noreferrer"
                target="_blank"
                href={"https://paper.xyz/wallet"}
              >
                https://paper.xyz/wallet
              </a>{" "}
              and log-in with your email address. Once you click on the ‘Login
              to Paper’ button, a new window will open, which you can close
              (once loaded completely) and return to the previous page where you
              entered your email address.
            </p>
            <p className="bold-header underline text-xl">
              5. Where else can I view my NFT?
            </p>
            <p className="text-xl mb-4">
              You can also view your NFT on{" "}
              <a
                className="text-blue-600"
                rel="noreferrer"
                target="_blank"
                href={"https://nftrade.com"}
              >
                https://nftrade.com
              </a>{" "}
              by connecting your MetaMask wallet address. NFTrade is a
              cross-chain and blockchain-agnostic NFT platform. They are an
              aggregator of all NFT marketplaces and host the complete NFT
              lifecycle, allowing anyone to seamlessly create, buy, sell, swap,
              farm, and leverage NFTs across different blockchains such as
              Avalanche, Ethereum, Polygon, Binance Smart Chain.
            </p>
          </div>
          <p className="bold-header text-2xl">
            How many Creators are involved in this project?
          </p>
          <p className="text-xl mb-4">
            x26 Creators to reflect the characters in the alphabet.
          </p>
          <p className="bold-header text-2xl">
            How many Helvetica NFTs are available within this project?
          </p>
          <p className="text-xl mb-4">
            Each Creator has created x3 artworks.
            <br /> #1. x50 NFTs are available
            <br /> #2. x150 NFTs are available
            <br /> #3. x300 NFTs are available
          </p>
          <p className="bold-header text-2xl">
            How and where can I resell my already purchased Helvetica NFT?
          </p>
          <p className="text-xl mb-4">
            Our partner for the secondary market is KALAO.{" "}
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={"https://marketplace.kalao.io/"}
            >
              (https://marketplace.kalao.io/)
            </a>{" "}
          </p>
          <p className="text-xl mb-4">
            Below are specific instructions on how to direct sell a Helvetica
            NFT using C-Chain in KALAO.
            <br />{" "}
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={
                "https://docs.kalao.io/faq/kalao-faq/kalao-marketplace-advanced/c-chain/how-to-direct-sell-an-nft-using-c-chain"
              }
            >
              https://docs.kalao.io/faq/kalao-faq/kalao-marketplace-advanced/c-chain/how-to-direct-sell-an-nft-using-c-chain
            </a>{" "}
          </p>
          <p className="bold-header text-2xl">
            Will there be another KnownUnknown x Monotype NFT project in the
            future?
          </p>
          <p className="text-xl mb-4">
            We are discussing future digital projects and how to further engage
            the creative community.
          </p>
          <p className="bold-header text-2xl">Contacts</p>
          <p className="text-xl mb-4">
            Regarding check-out payments, wallet and general NFT enquiries,
            please contact;
            <br />
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={"mailto:team@paper.xyz"}
            >
              team@paper.xyz
            </a>
          </p>
          <p className="text-xl mb-4">
            Regarding Helvetica Now Variable and any font enquiries, please
            contact;
            <br />{" "}
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={"mailto:support@monotype.com"}
            >
              support@monotype.com
            </a>
          </p>
          <p className="text-xl mb-4">
            Regarding brand galleries and creative collaborations on future NFT
            projects, please contact;
            <br />{" "}
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={"mailto:hello@knownunknown.com"}
            >
              hello@knownunknown.com
            </a>
          </p>
          <p className="text-xl mb-4">
            Regarding secondary NFT sales and the FREE Helvetica NFTs, please
            contact;
            <br />{" "}
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={"mailto:hello@kalao.io"}
            >
              hello@kalao.io
            </a>
          </p>
          <p className="text-xl mb-[40px]">
            Regarding Avalanche, please visit their help center;
            <br />{" "}
            <a
              className="text-blue-600"
              rel="noreferrer"
              target="_blank"
              href={"https://support.avax.network/en/"}
            >
              https://support.avax.network/en/
            </a>
          </p>
        </>
      )}
      {version === "terms-and-conditions" && (
        <>
          <h1 className="bold-header text-4xl mt-[40px] mb-10">
            TERMS OF SERVICE:
          </h1>
          <p className="bold-header text-4xl mb-4">KnownUnknown, Inc.</p>
          <p className="text-xl mb-4">June 2022</p>
          <p className="text-xl mb-4">
            Welcome to the website (the “Site”) of KnownUnknown.
            (“KnownUnknown,” “we,” “us,” or “our”) provides a platform and
            marketplace for purchasing and browsing Digital Artwork (described
            below) to you (“you” or “user” through it&#39;s website, platform
            and marketplace located at www.knownunknown.com (the “Platform”),
            subject to the following Terms of Service.
          </p>
          <p className="text-xl mb-4">
            By signing up for an account on the Platform or otherwise using or
            accessing the Platform, you acknowledge that you have read and agree
            to these Terms.{" "}
            <Link className="text-blue-600" to={"/privacy-policy"}>
              The Privacy Policy
            </Link>{" "}
            and all such additional terms, guidelines, and rules as set forth on
            the Platform are hereby incorporated by reference into these Terms
            and expressly agreed to and acknowledged by the User.
          </p>
          <p className="text-xl mb-4">
            PLEASE READ THESE TERMS CAREFULLY. THEY CONTAIN AN AGREEMENT TO
            ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL
            RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE
            REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE
            AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU
            WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST FOUNDATION ON AN
            INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
            REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED
            TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY
            RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE
            ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF
            LAW.
          </p>
          <p className="text-xl mb-4">
            We reserve the right to change or modify portions of these Terms at
            any time. If we do this, we will post the changes on this page{" "}
            <span className="underline">
              and will indicate at the top of this page the date these Terms
              were last revised.
            </span>{" "}
            Your use of the Platform after the date any such changes become
            effective constitutes your acceptance of the new Terms of Service.
          </p>
          <h3 className="bold-header text-xl">1) What Is KnownUnknown?</h3>
          <p className="text-xl mb-4">
            KnownUnknown provides a platform and NFT Marketplace for Users,
            including artists (“Creators”) and collectors (“Collectors”), to
            purchase, list for auction, make offers on, and bid on (each a
            “Transaction”) Digital Artwork/NFTs (as defined below).
          </p>
          <p className="text-xl mb-4">
            a) Smart-Contract Enabled. “Digital Artwork” on the Platform refers
            to a non-fungible Ethereum-based token, that uses smart contracts on
            the Avalanche blockchain (“Smart Contracts”). The Ethereum
            blockchain provides an immutable ledger of all transactions that
            occur on the blockchain. This means that all Digital Artwork is
            outside of the control of any one party, including KnownUnknown, and
            is subject to many risks and uncertainties. We neither own nor
            control MetaMask, Avalanche, Coinbase, the Ethereum network, the
            smart contracts on which Collections (as defined below) are based
            (“Collection Smart Contracts”), your browser, or any other third
            party site, product, or service that you might access, visit, or use
            for the purpose of enabling you to use the various features of the
            Platform. We will not be liable for the acts or omissions of any
            such third parties, nor will we be liable for any damage that you
            may suffer as a result of your transactions or any other interaction
            with any such third parties. You understand that your Digital Wallet
            public address will be made publicly visible whenever you engage in
            a Transaction on the Platform.
          </p>
          <h3 className="bold-header text-xl">2) How Do I Use KnownUnknown?</h3>
          <p className="text-xl mb-4">
            a) Anyone can browse the Platform without registering for an
            account. You may be required to register with KnownUnknown in order
            to access and use certain features on the Platform, such as
            participating as a Creator or Collector. If you choose to register
            on the Platform, you agree to provide and maintain accurate and
            current information about yourself, as prompted by our registration
            form. Registration data and certain other information about you are
            governed by our Privacy Policy.{" "}
            <span className="bold-header">
              You must be at least 16 years old to register for an account as a
              Creator, and at least 18 years old to place a bid on any Digital
              Artwork.
            </span>{" "}
            If you are between 16 and 18 years old, you must have the expressed
            permission of a parent or legal guardian who can accept these Terms
            on your behalf. You are responsible for anything that occurs when
            anyone is signed in to your account, as well as the security of the
            account.
          </p>
          <p className="text-xl mb-4">
            b) You are responsible for maintaining the confidentiality of your
            account and password, if any, and are fully responsible for any and
            all activities that occur under your password or account. You agree
            to immediately notify KnownUnknown of any unauthorized use of your
            password or account or any other breach of security, and (b) ensure
            that you exit from your account at the end of each session when
            accessing the Platform. KnownUnknown will not be liable for any loss
            or damage arising from your failure to comply with this Section.
          </p>
          <p className="text-xl mb-4">
            c) In order to participate as a Creator or Collector on the
            Platform, you must connect your account to your digital wallet
            supported on MetaMask, Avalanche, or other wallet extensions or
            gateways as allowed on the Platform. Such digital wallets allow you
            to purchase, store, and engage in transactions using the Avalanche
            cryptocurrency, AVAX. All Transactions on the Platform are in the
            Avalanche cryptocurrency, AVAX.
          </p>
          <p className="text-xl mb-4">
            d) KnownUnknown reserves the right to modify or discontinue,
            temporarily or permanently remove the Platform with or without
            notice. You agree that KnownUnknown will not be liable to you or to
            any third party for any modification, suspension, or discontinuance
            of the Platform.
          </p>
          <h3 className="bold-header text-xl">
            3) What Are The Rules For Using KnownUnknown?
          </h3>
          <p className="text-xl mb-4">
            When using the Platform, you will not directly or indirectly:
            <ul className="list-disc ml-5">
              <li>
                Engage in deceptive or manipulative trading activities in any
                way, including bidding on your own items, preventing bidding,
                placing misleading bids or offers, or using the Platform to
                conceal economic activity.
              </li>
              <li>
                Email, upload, or otherwise distribute any content, including
                User Content (as defined below), that (i) infringes any
                intellectual property or other proprietary rights of any party;
                (ii) you do not have a right to do so under any law or under
                contractual or fiduciary relationships; (iii) contains software
                viruses or any other computer code, files or programs designed
                to interrupt, destroy or limit the functionality of any computer
                software or hardware or telecommunications equipment; (iv) poses
                or creates a privacy or security risk to any person or entity;
                (v) constitutes unsolicited or unauthorized advertising,
                promotional materials, commercial activities and/or sales, “junk
                mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,”
                “sweepstakes,” or any other form of solicitation; (vi) is
                unlawful, harmful, threatening, abusive, harassing, tortious,
                excessively violent, defamatory, vulgar, obscene, pornographic,
                libelous, invasive of another’s privacy, hateful racially,
                ethnically or otherwise objectionable (including any sexual
                depictions of minors); or (vii) in the sole judgment of
                KnownUnknown, is objectionable or which restricts or inhibits
                any other person from using or enjoying the Platform, or which
                may expose KnownUnknown or its Users to any harm or liability of
                any type;
              </li>
              <li>
                interfere with or disrupt the Platform or servers or networks
                connected to the Platform in any manner that could negatively
                affect or inhibit other Users from fully enjoying the Platform
                or that could damage, disable, overburden or impair the
                functioning of the Platform in any manner;{" "}
              </li>
              <li>
                disobey any requirements, procedures, policies or regulations of
                networks connected to the Platform;{" "}
              </li>
              <li>
                violate any applicable local, state, national or international
                law, or any regulations having the force of law, including but
                not limited to the U.S. Department of Treasury’s Office of
                Foreign Assets Control (“OFAC”), or which would involve proceeds
                of any unlawful activity;{" "}
              </li>
              <li>create or list counterfeit items or assets;</li>
              <li>
                impersonate any person or entity, or falsely state or otherwise
                misrepresent your affiliation with a person or entity;
              </li>
              <li>
                solicit personal information from anyone under the age of 18;
              </li>
              <li>
                harvest or collect email addresses or other contact information
                of other Users from the Platform by electronic or other means
                for the purposes of sending unsolicited emails or other
                unsolicited communications;
              </li>
              <li>
                advertise or offer to sell or buy any goods or services for any
                business purpose that is not specifically authorized;
              </li>
              <li>
                further or promote any criminal activity or enterprise or
                provide instructional information about illegal activities,
                including for the purpose of concealing economic activity,
                laundering money, or financing terrorism;{" "}
              </li>
              <li>
                obtain or attempt to access or otherwise obtain any materials or
                information through any means not intentionally made available
                or provided for through the Platform;{" "}
              </li>
              <li>
                use any robot, spider, site search/retrieval application, or
                other device to retrieve or index any portion of the Platform or
                the content posted on the Platform, or to collect information
                about its Users for any unauthorized purpose;
              </li>
              <li>
                create user accounts by automated means or under false or
                fraudulent pretenses;
              </li>
              <li>
                access or use the Platform to carry out financial activities
                subject to registration or licensing, including but not limited
                to creating, listing, or buying securities, commodities,
                options, real estate, or debt instruments;
              </li>
              <li>
                access or use the Platform to participate in fundraising for a
                business, protocol, or platform, including but not limited to
                creating, listing, or buying assets that are redeemable for
                financial instruments, assets that give Creators, Collectors, or
                Users rights to participate in an ICO or any securities
                offering, or assets that entitle Creators, Collectors, or Users
                to financial rewards, including but not limited to, DeFi yield
                bonuses, staking bonuses, and burn discounts, provided that the
                foregoing will not restrict the legal use of any proceeds
                resulting from your permitted use of the Platform; or
              </li>
              <li>
                access or use the Platform for the purpose of creating a product
                or service that is competitive with any of our products or
                services.
              </li>
            </ul>
          </p>
          <h3 className="bold-header mb-4 text-xl">
            4) What are the intellectual property rights on the Platform?
          </h3>
          <p className="text-xl mb-4">
            <span className="bold-header">a) Creator/Artist Rights</span>
            <br /> i) The Creator owns all legal right, title, and interest in
            all intellectual property rights of the content underlying the
            Digital Artwork minted by the Creator on the Platform (such
            underlying content, the “Art Content”) and any content specific to a
            Collection that is not the Digital Artwork within a Collection
            (“Collection Content”), including but not limited to copyrights and
            trademarks in the Art Content and Collection Content. As the
            copyright owner, the Creator has the right to reproduce, prepare
            derivatives of, distribute, and display or perform the Art Content
            and Collection Content.
            <br /> ii) The Creator hereby acknowledges, understands, and agrees
            that selling such Digital Artwork on the Platform constitutes an
            express representation, warranty, and covenant that the Creator (a)
            has not sold, tokenized or created another cryptographic token, and
            (b) will not, and will not cause another to, sell, tokenize, or
            create another cryptographic token, in each case representing a
            digital collectible for the same Art Content underlying such Digital
            Artwork.
            <br /> iii) By launching any Digital Artwork on the Platform, the
            Creator hereby expressly and affirmatively grants to the Collector
            and, to the extent applicable, any subsequent Collector in a
            Secondary Sale.
            <br /> iv) Creator expressly represents and warrants that its Art
            Content underlying any Digital Artwork launched on the Platform and
            Collection Content contain only original content otherwise
            authorized for use by the Creator, and do not contain unlicensed or
            unauthorized copyrighted content, including any imagery, design,
            audio, video, human likeness, or other unoriginal content not
            created by the Creator, not authorized for use by the Creator, not
            in the public domain, or otherwise without a valid claim of fair
            use.
            <br /> v) The Creator further represents and warrants that it has
            permission to incorporate the unoriginal content, to the extent such
            permission is legally required.
            <br /> (vi) User hereby acknowledges, understands, and agrees that
            by launching, listing, or selling any Digital Artwork on the
            Platform, User hereby expressly and affirmatively grants to
            KnownUnknown, and its Affiliates (as defined below) and its and
            their successors, a non-exclusive, world-wide, transferable,
            sublicensable, perpetual, irrevocable, and royalty-free license to
            (a) reproduce, display, perform, distribute and transmit the Art
            Content or Non-KnownUnknown Content underlying such Digital Artwork,
            and Collection Content, for the purpose of operating and developing
            the Platform, and (b) use and incorporate the Art Content or
            Non-KnownUnknown Content underlying such Digital Artwork, and
            Collection Content, or derivative works of any of the foregoing, on
            any marketing materials, and to reproduce, display, perform, display
            and transmit such marketing materials on any media whether now known
            or later discovered for the purposes of operating, promoting,
            sharing, marketing, and advertising the Platform. <br />
            (vii) The foregoing licenses include, without limitation, the
            express rights to: (i) display or perform the Art Content or
            Non-KnownUnknown Content underlying such Digital Artwork, and
            Collection Content, on the Platform, a third party platform, social
            media posts, blogs, editorials, advertising, market reports, virtual
            galleries, museums, virtual environments, editorials, or to the
            public; (ii) index the Art Content, Non-KnownUnknown Content and
            Collection Content in electronic databases, indexes, and catalogs;
            and (iii) host, store, distribute, and reproduce one or more copies
            of such Art Content and Collection Content within a distributed file
            keeping system, node cluster, or other database or cause, direct, or
            solicit others to do so. For purposes hereof, “Affiliate” means an
            entity which, directly or indirectly, owns or controls, is owned or
            is controlled by or is under common ownership or control with
            KnownUnknown.
          </p>
          <p className="text-xl mb-4">
            <span className="bold-header">b) Collector Rights</span>
            <br /> i) The Collector receives a cryptographic token representing
            the Creator’s Art Content as a piece of property, but does not own
            the Art Content itself or any intellectual property rights therein.
            The Collector may display and share the Art Content, but the
            Collector does not have any legal ownership, right, or title to any
            copyrights, trademarks, or other intellectual property rights to the
            Art Content, except the limited license to the Art Content granted
            by these Terms.
            <br /> ii) Upon legally collecting and obtaining ownership of any
            Digital Artwork launched on the Platform, the Collector receives
            from the seller of such Digital Artwork a limited, worldwide,
            non-assignable and non-transferable (except as expressly set forth
            below), non-sublicensable, royalty-free license to display the Art
            Content underlying such Digital Artwork solely for the Collector’s
            non-commercial purposes, including the right to display such Art
            Content privately or publicly: (i) for the purpose of promoting or
            sharing the Collector’s purchase of, ownership of, or interest in
            such Digital Artwork, (ii) for the purpose of sharing, promoting,
            discussing, or commenting on such Art Content; (iii) on third party
            marketplaces, exchanges, platforms, or applications in association
            with an offer to sell, or trade, the Digital Artwork; and (iv)
            within decentralized virtual environments, virtual worlds, virtual
            galleries, virtual museums, or other navigable and perceivable
            virtual environments (the “Collector License”).
            <br /> (iii) Upon any sale or transfer of the Digital Artwork to
            another collector, (a) the foregoing license will automatically
            transfer to such other collector, and such other collector will be
            deemed the “Collector” and will be subject to these Terms and (b)
            the seller or transferor of such Digital Artwork will cease to have
            any further rights to such Art Content.
            <br /> (iv) If a Collector sells, trades or transfers its Digital
            Artwork to a subsequent Collector, regardless of whether on or off
            the Platform, such Collector represents and warrants that it will
            (i) notify the subsequent Collector of these Terms and (ii) require
            the subsequent Collector to comply with these Terms.
            <br /> v) The Collector agrees that it will not, and will not permit
            any third party to, do or attempt to do any of the foregoing without
            the Creator’s express prior written consent in each case: (i)
            modify, distort, mutilate, or perform any other modification to the
            Art Content which would be prejudicial to the Creator’s honor or
            reputation; (ii) use the Digital Artwork or underlying Art Content
            to advertise, market, or sell any third party product or service;
            (iii) use the Digital Artwork or underlying Art Content in
            connection with images, videos, or other forms of media that depict
            hatred, intolerance, violence, cruelty, or anything else that could
            reasonably be found to constitute hate speech or otherwise infringe
            upon the rights of others; (iv) incorporate the Digital Artwork or
            underlying Art Content in movies, videos, video games, or any other
            forms of media for a commercial purpose, except to the limited
            extent that such use is expressly permitted by these Terms or solely
            for Collector’s personal, non-commercial use; (v) sell, distribute
            for commercial gain, or otherwise commercialize merchandise that
            includes, contains, or consists of the Digital Artwork or underlying
            Art Content; (vi) attempt to trademark, copyright, or otherwise
            acquire additional intellectual property rights in or to the Digital
            Artwork or underlying Art Content; (vii) attempt to mint, tokenize,
            or create an additional cryptographic token representing the same
            Digital Artwork or underlying Art Content, whether on or off of the
            Platform; (viii) falsify, misrepresent, or conceal the authorship of
            the Digital Artwork or underlying Art Content; or (ix) otherwise
            utilize any Art Content or, except as expressly permitted under
            these Terms, the Digital Artwork for the Collector’s or any third
            party’s commercial benefit. Collector agrees that it receives no
            rights to any Collection Content and will not use, copy, distribute
            or otherwise exploit the Collection Content in any manner.
            <br /> vi) Collector irrevocably releases, acquits, and forever
            discharges KnownUnknown and its Affiliates and their officers and
            successors of any liability for direct or indirect copyright or
            trademark infringement for KnownUnknown’s use of any Digital Artwork
            or underlying Art Content, or any Collection Content, in accordance
            with these Terms.
          </p>
        </>
      )}
      {version === "privacy-policy" && (
        <>
          <h1 className="bold-header text-4xl mt-[40px] mb-10">
            PRIVACY POLICY:
          </h1>
          <p className="bold-header text-4xl mb-4">KnownUnknown, Inc.</p>
          <p className="text-xl mb-4">June 2022</p>
          <p className="text-xl mb-4">
            Welcome to the website (the “Site”) of KnownUnknown.
            (“KnownUnknown,” “we,” “us,” or “our”). We provide a platform for
            purchasing and browsing Digital Artwork.
          </p>
          <p className="mb-4 text-xl">
            We are committed to protecting your privacy. This Privacy Policy
            explains what personal information we collect from you, how we use
            and share that information, and your choices concerning our
            information practices. Before using this platform or submitting any
            personal information to KnownUnknown, please review this Privacy
            Policy and contact us if you have any questions via
            hello@knownunknown.com.
          </p>
          <p className="mb-4 text-xl">
            By using this platform, you agree to the practices described in this
            Privacy Policy. If you do not agree to this Privacy Policy, please
            do not access the Site or otherwise use this platform. This Privacy
            Policy is incorporated into and forms part of our{" "}
            <Link className="text-blue-600" to={"/terms-and-conditions"}>
              Terms of Service
            </Link>
            . Capitalized terms not defined in this Privacy Policy have the
            meanings assigned to them in the Terms of Service. “NFT” in this
            Privacy Policy means a non-fungible token or similar digital item
            implemented on a blockchain (such as the Ethereum blockchain), which
            uses smart contracts to link to or otherwise be associated with
            certain content or data.
          </p>
          <h3 className="bold-header text-xl">
            1. Personal Information Collection
          </h3>
          <p className="mb-4 text-xl">
            We collect the following categories of information from you:
            <ul className="list-disc ml-5">
              <li>
                Identification Information: Your name, email address, digital
                wallet address and any other information you directly provide us
                on or through the platform.
              </li>
              <li>
                Communication Information: We may collect information when you
                contact us with questions or concerns and when you voluntarily
                respond to questionnaires, surveys or requests for market
                research seeking your opinion and feedback.
              </li>
              <li>
                Commercial Information: We may retain a history of the Digital
                Artwork or Art Content you browse, sell, and/or purchase using
                the Service.
              </li>
              <li>
                Social Media Information: We maintain a social media presence on
                platforms such as Instagram, Twitter, LinkedIn and Discord. When
                you interact with us via any of these platforms, we may receive
                personal information that you provide or make available to us
                based on your settings, such as your profile information. We
                also collect any social media profile information you directly
                provide us.
              </li>
            </ul>
          </p>
          <p className="mb-4 text-xl">
            Internet Activity Information: When you visit, use, and interact
            with our platform, we may automatically log the following
            information:
            <ul className="list-disc ml-5">
              <li>
                Device Information: The manufacturer, model, operating system,
                browser type, IP address, and unique identifiers of the device
                you use to access the Service.
              </li>
              <li>
                Usage Information: Information about how you use our Service,
                such as the type of content that you view or engage with, the
                features you use, the actions you take and duration of your
                activities.
              </li>
              <li>
                Location Information: We may derive a rough estimate of your
                location from your IP address when you visit the Site.
              </li>
              <li>
                Email Open/Click Information: We may use pixels in our email
                campaigns via MailChimp, that allow us to collect your email and
                IP address as well as the date and time you open an email or
                click on any links in emails.
              </li>
            </ul>
          </p>
          <p className="mb-4 text-xl">
            We may use the following technologies to collect Internet Activity
            Information:
            <ul className="list-disc ml-5">
              <li>
                Cookies, which are text files stored on your device to uniquely
                identify your browser or to store information or settings in the
                browser to help you navigate between pages efficiently, remember
                your preferences, enable functionality, help us understand user
                activity and patterns and facilitate online advertising.
              </li>
              <li>
                Local storage technologies, like HTML5, that provide
                cookie-equivalent functionality but can store larger amounts of
                data, including on your device outside of your browser in
                connection with specific applications.
              </li>
              <li>
                Web beacons, also known as pixel tags or clear GIFs, which are
                used to demonstrate that a webpage or email was accessed or
                opened, or that certain content was viewed or clicked.
              </li>
            </ul>
          </p>
          <h3 className="bold-header text-xl">2. Personal Information Use</h3>
          <p className="mb-4 text-xl">
            We use your personal information for the following purposes:
            <ul className="list-disc ml-5">
              <li>Provide, operate, maintain, and secure our platform.</li>
              <li>
                Create, maintain, and authenticate your account, whilst
                processing transactions.
              </li>
              <li>
                Send you updates about administrative matters such as changes to
                our terms or policies; and provide user support, respond to your
                requests, questions and feedback.
              </li>
              <li>
                Improve our platform and create new features, personalize your
                experience and derive insights from de-identified and aggregated
                information.
              </li>
              <li>
                Direct Marketing: To send you marketing communications,
                including notifying you of future NFT drops, collaborations,
                offers and events via email.
              </li>
              <li>
                Comply with applicable laws, lawful requests, and legal process,
                such as to respond to subpoenas or requests from government
                authorities, protect our, your or others&#39; rights, privacy,
                safety or property (including by making and defending legal
                claims), audit our compliance with legal and contractual
                requirements and internal policies; and prevent, identify,
                investigate and deter fraudulent, harmful, unauthorized,
                unethical or illegal activity, including cyberattacks and
                identity theft.
              </li>
            </ul>
          </p>
          <h3 className="bold-header text-xl">
            3. Personal Information Sharing
          </h3>
          <p className="mb-4 text-xl">
            Our Sharing: We may share personal information with:
            <ul className="list-disc ml-5">
              <li>
                Service Providers, including hosting services, email services,
                advertising and marketing services, payment processors, customer
                support services, and analytics services.
              </li>
              <li>
                Professional Advisors, such as lawyers and accountants, where
                doing so is necessary to facilitate the services they render to
                us.
              </li>
              <li>
                Business Transaction Recipients, such as counterparties and
                others assisting with a merger, acquisition, financing,
                reorganization, bankruptcy, receivership, dissolution, asset
                sale, or similar transaction, and with successors or affiliates
                as part of or following that transaction.
              </li>
              <li>
                Government Authorities, where required to do so for the
                Compliance and Protection purposes described above.
              </li>
              <li>
                Affiliates: We may share personal information with our current
                and future affiliates, meaning an entity that controls, is
                controlled by, or is under common control with KnownUnknown. Our
                affiliates may use the personal information we share in a manner
                consistent with this Privacy Policy.
              </li>
              <li>
                If you are a Creator, we may, at our sole discretion, share your
                personal information with individuals who claim that your
                Digital Artwork or Art Content may infringe their intellectual
                property and other proprietary rights.
              </li>
            </ul>
            Your Sharing: Your transactions through the Service will be recorded
            on the blockchain and associated with your wallet ID.
          </p>
          <h3 className="bold-header text-xl">5. Links to Other Websites</h3>
          <p className="mb-4 text-xl">
            The Platform may contain links to other websites not operated or
            controlled by KnownUnknown, including social media services (“Third
            Party Sites”). The information that you share with Third Party Sites
            will be governed by the specific privacy policies and terms of
            service of the Third Party Sites and not by this Privacy Policy. By
            providing these links we do not imply that we endorse or have
            reviewed these sites. Please contact the Third Party Sites directly
            for information on their privacy practices and policies.
          </p>
          <h3 className="bold-header text-xl">6. Security</h3>
          <p className="mb-4 text-xl">
            We employ a number of technical l safeguards, designed to protect
            the personal information we collect. However, no security measures
            are failsafe and we cannot guarantee the security of your personal
            information and you use the Platform at your own risk.
          </p>
          <h3 className="bold-header text-xl">7. Your Choices</h3>
          <p className="mb-4 text-xl">
            You can contact us and request any updates or corrections needed to
            keep your personal information accurate and current. You may opt out
            of marketing communications by following the unsubscribe
            instructions in any marketing email we send you.
          </p>
          <h3 className="bold-header text-xl">
            7. Changes to the Privacy Policy
          </h3>
          <p className="mb-4 text-xl">
            The Platform may change from time to time. As a result, we may
            change this Privacy Policy at any time. When we do, we will post an
            updated version on this page with the stipulated date, unless
            another type of notice is required by applicable law. By continuing
            to use our Platform or providing us with personal information after
            we have posted an updated Privacy Policy, or notified you by other
            means if applicable, you consent to the revised and most recent
            Privacy Policy and the practices described in it.
          </p>
          <h3 className="bold-header text-xl">8. Contact Us</h3>
          <p className=" text-xl mb-[40px]">
            If you have any questions about our Privacy Policy, please feel free
            to contact us at{" "}
            <span className="bold-header">hello@knownunknown.com.</span>
          </p>
        </>
      )}
    </div>
  );
}
