import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-[#0E1231] text-white text-sm">
      <div className="max-w-[1440px] mx-auto grid grid-cols-2 px-4 py-6 md:py-[60px] md:px-[88px]">
        <div className="col-span-2 md:col-span-1 flex flex-col items-start mb-10 md:mb-0">
          <Link className="w-fit h-fit" to={"/"}>
            <img
              className="max-h-6 mb-10"
              src={"/static/logolight.png"}
              alt={"logo"}
            />
          </Link>

          <Link className="hover:text-[#6C00FF]" to={"/about"}>
            About
          </Link>
          <Link className="hover:text-[#6C00FF]" to={"/FAQs"}>
            FAQs
          </Link>
          <Link className="hover:text-[#6C00FF]" to={"/terms-and-conditions"}>
            Terms & Conditions
          </Link>
          <Link className="hover:text-[#6C00FF]" to={"/privacy-policy"}>
            Privacy Policy
          </Link>
        </div>
        <div className="col-span-2 md:col-span-1 flex flex-row justify-start md:justify-end items-center md:items-end">
          <div className="mr-10 flex flex-row gap-4">
            <a
              href={"https://twitter.com/KnownUnknownCom"}
              rel="noreferrer"
              target="_blank"
            >
              <img
                className=""
                src={"/static/icons/twitter-icon.svg"}
                alt={"Twitter"}
              />
            </a>
            <a
              href={
                "https://discord.com/channels/923328063117361152/945695746840207361"
              }
              rel="noreferrer"
              target="_blank"
            >
              <img
                className=""
                src={"/static/icons/discord-icon.svg"}
                alt={"Discord"}
              />
            </a>
            <a
              href={
                "https://www.instagram.com/accounts/login/?next=/knownunknown.co/"
              }
              rel="noreferrer"
              target="_blank"
            >
              <img
                className=""
                src={"/static/icons/instagram-icon.svg"}
                alt={"Instagram"}
              />
            </a>
            <a
              href={"https://knownunknown.com/"}
              rel="noreferrer"
              target="_blank"
            >
              <img
                className=""
                src={"/static/icons/KU-icon.svg"}
                alt={"Known Unknown"}
              />
            </a>
          </div>
          <a
            href={
              "https://login.circle.so/sign_up?request_host=knownunknown.circle.so"
            }
            rel="noreferrer"
            target="_blank"
          >
            <button className="bg-[#6C00FF] text-[14px] leading-[14px] py-[13px] px-4 rounded">
              Join KU
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
