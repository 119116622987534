import { useState } from "react";
import { Link } from "react-router-dom";
import ConnectWallet from "./ConnectWallet";

export default function Navbar() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      <div className="flex justify-center text-[14px]">
        <div className="fixed bg-white w-full max-w-[1440px] grid grid-cols-2 px-4 md:px-[88px] py-6 content-center">
          <Link className="w-fit h-fit self-center" to={"/"}>
            <img
              src={"/static/logodark.png"}
              alt={"logo"}
              className="max-h-6"
            />
          </Link>
          <div className="hidden md:flex flex-row items-center justify-end">
            <Link to={"/how-to"}>How to</Link>
            <div className="ml-10">
              <ConnectWallet />
            </div>
          </div>
          <div className="flex md:hidden flex-row items-center justify-end">
            <button
              type={"button"}
              onClick={() => {
                setMobileNavOpen(!mobileNavOpen);
              }}
            >
              <img
                src={"/static/icons/hamburger-icon.svg"}
                alt={"logo"}
                className="h-6"
              />
            </button>
          </div>
          <div className={mobileNavOpen ? "col-span-2 mt-6" : "hidden"}>
            <hr />
            <Link to={"/how-to"}>
              <div className="py-4 text-center">
                <span className="w-full">How to</span>
              </div>
            </Link>
            <hr />
            <div className="py-4">
              <ConnectWallet mobile={true} />
            </div>
            <hr />
          </div>
        </div>
      </div>
      <div className={mobileNavOpen ? "block h-48" : "block h-28"}></div>
    </>
  );
}
