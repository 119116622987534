import { useAddress, useMetamask, useDisconnect } from "@thirdweb-dev/react";
import { Tooltip } from "flowbite-react";

export default function ConnectWallet({ mobile = false }) {
  const connectWithMetamask = useMetamask();
  const disconnect = useDisconnect();
  const address = useAddress();

  return (
    <div
      className={
        mobile
          ? "text-center flex flex-row justify-center items-center gap-4"
          : "text-center"
      }
    >
      {address ? (
        <div className="flex flex-row items-center gap-4">
          {/* <p className="text-center">Connected as: {address}</p> */}
          <Tooltip
            content={
              <>
                <p>Wallet Address:</p>
                <p>{address}</p>
              </>
            }
          >
            <p className="text-center">Connected</p>
          </Tooltip>
          <button
            onClick={disconnect}
            className="text-white bg-[#6C00FF] py-[13px] px-4 rounded text-[14px] leading-[14px]"
          >
            Disconnect?
          </button>
        </div>
      ) : (
        <button
          onClick={connectWithMetamask}
          className="text-white bg-[#6C00FF] py-[13px] px-4 rounded text-[14px] leading-[14px]"
        >
          Connect Wallet
        </button>
      )}
    </div>
  );
}
