import { createContext, useReducer } from "react";
import { cloneDeep } from "lodash";

// Data structure:
// {
//   "ContractID": [nft, nft, nft],
//   "ContractID2": [nft, nft, nft],
// }
const initialState = {};

const reducer = (NFTState, action) => {
  switch (action.type) {
    case "ADD_CONTRACT":
      const newNFTState = cloneDeep(NFTState);
      newNFTState[action.payload.conAddress] = action.payload.contractData;
      // console.log("old", NFTState);
      // console.log("new", newNFTState);
      return newNFTState;
    default:
      return NFTState;
  }
};

export const NFTContext = createContext(initialState);

export const NFTContextProvider = ({ children }) => {
  const [NFTState, NFTDispatch] = useReducer(reducer, initialState);

  return (
    <NFTContext.Provider value={{ state: NFTState, dispatch: NFTDispatch }}>
      {children}
    </NFTContext.Provider>
  );
};
