import { Link, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { PaperCheckout } from "@paperxyz/react-client-sdk";
import { useAddress } from "@thirdweb-dev/react";
import SaleCard from "./SaleCard";
import LoadingSpinner from "./LoadingSpinner";
import { NFTContext } from "../contexts/NFTContext";

export default function Details({
  drops,
  avaxPrice,
  detailsPageData,
  rawData,
}) {
  const [mediaType, setMediaType] = useState("");
  const [conData, setConData] = useState({});
  const [currentNFT, setCurrentNFT] = useState({});
  const { id } = useParams();
  const address = useAddress();
  const context = useContext(NFTContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const conAddress = detailsPageData[id]?.contractAddress;
    if (!conAddress) {
      return navigate("/");
    }
    // console.log("context.state", context.state);
    if (context.state[conAddress]) {
      // console.log("data exists");
      const contractData = context.state[conAddress];

      setConData(contractData);

      const tokenID = detailsPageData[id].tokenID;

      const metaTraits = {};
      for (const trait of contractData[tokenID].nft.metadata.attributes) {
        metaTraits[trait.trait_type] = trait.value;
      }

      if (contractData[tokenID].nft.metadata.animation_url) {
        setMediaType("video");
      } else if (contractData[tokenID].nft.metadata.image) {
        setMediaType("image");
      }

      const checkoutFormatted =
        detailsPageData[id].checkoutID.split("/")[
          detailsPageData[id].checkoutID.split("/").length - 1
        ];

      setCurrentNFT({
        ...contractData[tokenID],
        metaData: contractData.metaData,
        metaTraits,
        checkoutID: checkoutFormatted,
      });
      setIsLoading(false);
      return;
    } else {
      const filteredData = rawData.filter(
        (el) => el.contractAddress === conAddress
      );
      Promise.all([
        drops[conAddress].claimConditions.getActive(filteredData[0].tokenID),
        drops[conAddress].claimConditions.getActive(filteredData[1].tokenID),
        drops[conAddress].claimConditions.getActive(filteredData[2].tokenID),
        drops[conAddress].getAll(),
        drops[conAddress].metadata.get(),
      ])
        .then((res) => {
          const contractData = {
            [filteredData[0].tokenID]: {
              nft: res[3][filteredData[0].tokenID],
              claim: res[0],
            },
            [filteredData[1].tokenID]: {
              nft: res[3][filteredData[1].tokenID],
              claim: res[1],
            },
            [filteredData[2].tokenID]: {
              nft: res[3][filteredData[2].tokenID],
              claim: res[2],
            },
            metaData: res[4],
          };
          // console.log("res", res[4]);
          // console.log("contractdata", contractData);

          context.dispatch({
            type: "ADD_CONTRACT",
            payload: { conAddress, contractData },
          });

          setConData(contractData);

          const tokenID = detailsPageData[id].tokenID;

          const metaTraits = {};
          for (const trait of contractData[tokenID].nft.metadata.attributes) {
            metaTraits[trait.trait_type] = trait.value;
          }

          if (contractData[tokenID].nft.metadata.animation_url) {
            setMediaType("video");
          } else if (contractData[tokenID].nft.metadata.image) {
            setMediaType("image");
          }

          const checkoutFormatted =
            detailsPageData[id].checkoutID.split("/")[
              detailsPageData[id].checkoutID.split("/").length - 1
            ];

          setCurrentNFT({
            ...contractData[tokenID],
            metaData: contractData.metaData,
            metaTraits,
            checkoutID: checkoutFormatted,
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          return navigate("/");
        });
    }
  }, [id]);

  // console.log(currentNFT);

  return (
    <>
      {isLoading && (
        <div className="max-w-[1440px] mx-auto px-4 md:px-[88px]">
          <div className="flex justify-center items-center h-[600px]">
            <LoadingSpinner />
          </div>
        </div>
      )}
      {currentNFT.nft && !isLoading && (
        <div className="max-w-[1440px] mx-auto px-4 md:px-[88px]">
          <Link className="flex flex-row justify-start mb-[28px]" to={"/"}>
            <img
              src={"/static/icons/back-icon.svg"}
              alt={"logo"}
              className="mr-2"
            />
            <span className="text-base">Back to collection</span>
          </Link>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
            <div className="bg-[#FAFAFA] w-full h-full">
              {mediaType === "image" && (
                <img
                  className="w-full p-6"
                  src={currentNFT.nft.metadata?.image}
                  alt={currentNFT.nft.metadata?.name}
                />
              )}
              {mediaType === "video" && (
                <video
                  key={currentNFT.nft.metadata?.animation_url}
                  controls
                  playsInline
                  className="w-full p-6"
                >
                  <source src={currentNFT.nft.metadata?.animation_url} />
                  Your browser can not play this video.
                </video>
              )}
            </div>
            <div className="flex flex-col justify-between">
              <div>
                <div className="text-[#BFBFBF] text-sm flex flex-row mb-2">
                  <span>{currentNFT?.metaData?.name}</span>
                  <img
                    src={"/static/icons/check-icon.svg"}
                    alt={"logo"}
                    className="ml-2"
                  />
                </div>
                <h1 className="bold-header text-[32px] md:text-[60px] leading-[68px] mb-2">
                  {currentNFT.nft.metadata?.name}
                </h1>
                <span className="text-[#BFBFBF] text-sm">
                  1 of {currentNFT?.claim?.maxQuantity}
                </span>
                <span className="text-[#BFBFBF] text-sm ml-2">|</span>
                <span className="text-[#BFBFBF] text-sm ml-2">
                  {currentNFT?.claim?.availableSupply} Remaining
                </span>
              </div>
              <div>
                <div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 mb-12">
                    <div className="flex flex-col mb-6 lg:mb-0">
                      <span className="text-[#999999] text-[12px] mb-[16px]">
                        Price
                      </span>
                      <span className="bold-header text-[40px] flex flex-row items-baseline mb-[16px]">
                        <span className="text-[40px]">
                          {`$${parseInt(
                            currentNFT?.claim?.currencyMetadata.displayValue
                          ).toFixed(2)}`}
                        </span>
                        <span className="flex flex-row ml-1">
                          <span className="bold-header text-[11px] leading-[1.25rem]">
                            USDC.e
                          </span>
                          <img
                            src={"/static/icons/usdc.png"}
                            alt={"logo"}
                            className="ml-1 h-4"
                          />
                        </span>
                      </span>
                      <span className="text-[#999999] text-[14px]">
                        {avaxPrice &&
                          `(${(
                            currentNFT?.claim?.currencyMetadata.displayValue /
                            avaxPrice
                          ).toFixed(4)} AVAX)`}
                      </span>
                    </div>
                    <div className="border-t-[1px] lg:border-t-0 pt-6 lg:pt-0 lg:border-l-2 border-[#EBEBEB] flex flex-row items-center justify-center gap-8">
                      <img
                        src={"/static/icons/mastercard-icon.svg"}
                        alt={"logo"}
                        className="ml-1 h-[34px]"
                      />
                      <img
                        src={"/static/icons/amex-icon.svg"}
                        alt={"logo"}
                        className="ml-1 h-[34px]"
                      />
                      <img
                        src={"/static/icons/visa-icon.svg"}
                        alt={"logo"}
                        className="ml-1 h-[34px]"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <PaperCheckout
                      checkoutId={currentNFT.checkoutID}
                      recipientWalletAddress={address}
                    >
                      <button
                        type="button"
                        className="bold-header bg-[#6C00FF] text-[16px] leading-[16px] py-5 px-4 rounded-[10px] text-white mb-4 w-full"
                      >
                        Buy now
                      </button>
                    </PaperCheckout>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
            <div className="bg-[#FAFAFA] p-6">
              <div className="mb-10">
                <h4 className="bold-header text-lg mb-4">
                  Artwork Description
                </h4>
                <p className="whitespace-pre-wrap light-paragraph text-[16px] leading-[24px] mb-[18px]">
                  {currentNFT?.nft?.metadata?.description}
                </p>
              </div>
              <div>
                <h4 className="bold-header  text-lg mb-4">Creator</h4>
                <p className="whitespace-pre-wrap light-paragraph text-[16px] leading-[24px] mb-[18px]">
                  {currentNFT?.metaData?.description}
                </p>
                <div className="text-sm items-center flex mt-10">
                  <span>{currentNFT?.metaData?.name}</span>
                  <img
                    src={"/static/icons/check-icon.svg"}
                    alt={"logo"}
                    className="ml-2"
                  />
                  {/* <a
                    href={
                      "https://www.instagram.com/accounts/login/?next=/knownunknown.co/"
                    }
                    rel="noreferrer"
                    target="_blank"
                    className="ml-3"
                  >
                    <img
                      className="h-7"
                      src={"/static/icons/instagram-icon-dark.svg"}
                      alt={"Instagram"}
                    />
                  </a>
                  <a
                    href={"https://twitter.com/KnownUnknownCom"}
                    rel="noreferrer"
                    target="_blank"
                    className="ml-3"
                  >
                    <img
                      className="h-7"
                      src={"/static/icons/twitter-icon-dark.svg"}
                      alt={"Twitter"}
                    />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="bg-[#FAFAFA] p-6">
              <div className="mb-10">
                <h4 className="bold-header  text-lg mb-4">
                  Artwork Attributes
                </h4>
                <p className="light-paragraph text-[16px] leading-[24px] mb-[18px]">
                  Access to Monotype and KnownUnknown future live events.
                </p>
              </div>
              <div className="">
                <h4 className="bold-header text-lg mb-2">Collection</h4>
                <div className="mb-6">
                  <img
                    className=""
                    src="/static/hel-collection2.png"
                    alt="Collection"
                  />
                </div>
                {/* <h1 className="bold-collection text-[26px] md:text-[50px] leading-[68px] mb-2">
                  Helvetica The NFT.
                </h1>
                <p className="light-paragraph text-[16px] leading-[24px] mb-[18px]">
                  Monotype's first ever digital art collection, in partnership
                  with Avalanche and the KnownUnknown creative community.
                </p>
                <p className="light-paragraph text-[16px] leading-[24px]">
                  Prolific artists collaborate to create a collection unique and
                  personal to each artist leveraging #HelveticaNow Variable
                  font.
                </p> */}
              </div>
            </div>
          </div>
          <div>
            <hr className="mb-10" />
            <h3 className="text-lg mb-10">Other pieces from artist</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-14 md:mb-20">
              {Object.keys(conData)
                .filter((key) => key !== "metaData")
                .map((key) => (
                  <SaleCard
                    key={key}
                    conData={conData}
                    SaleCardData={conData[key]}
                    contractAddress={detailsPageData[id].contractAddress}
                    avaxPrice={avaxPrice}
                    data={rawData}
                  />
                ))}
              {/* <SaleCard
                key={2}
                conData={conData}
                SaleCardData={conData[1]}
                contractAddress={detailsPageData[id].contractAddress}
                avaxPrice={avaxPrice}
                data={rawData}
              />
              <SaleCard
                key={3}
                conData={conData}
                SaleCardData={conData[2]}
                contractAddress={detailsPageData[id].contractAddress}
                avaxPrice={avaxPrice}
                data={rawData}
              /> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
