import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PaperCheckout } from "@paperxyz/react-client-sdk";
import { useAddress } from "@thirdweb-dev/react";

export default function SaleCard({
  SaleCardData,
  contractAddress,
  avaxPrice,
  data,
  conData,
}) {
  const [cardData, setCardData] = useState({});
  const [mediaType, setMediaType] = useState("");
  const address = useAddress();

  useEffect(() => {
    if (SaleCardData) {
      const currentNFT = data.find(
        (el) =>
          el.tokenID == SaleCardData.nft.metadata.id &&
          el.contractAddress === contractAddress
      );
      const link = currentNFT.link;
      const metaTraits = {};
      for (const trait of SaleCardData.nft.metadata.attributes) {
        metaTraits[trait.trait_type] = trait.value;
      }
      if (SaleCardData.nft.metadata.animation_url) {
        setMediaType("video");
      } else if (SaleCardData.nft.metadata.image) {
        setMediaType("image");
      }

      const checkoutFormatted =
        currentNFT.checkoutID.split("/")[
          currentNFT.checkoutID.split("/").length - 1
        ];

      setCardData({
        ...SaleCardData,
        metaTraits,
        checkoutID: checkoutFormatted,
        link,
      });
    }
  }, [SaleCardData]);

  return (
    <>
      {cardData.nft && (
        <div>
          <div className="bg-[#FAFAFA] p-4">
            <div className="flex flex-row gap-2 mb-[22px]">
              <span className="text-[16px]">{conData?.metaData?.name}</span>
              <img
                src={"/static/icons/check-icon.svg"}
                alt={"checkmark"}
                className=""
              />
            </div>
            <div className="bold-header text-[24px] mb-2">
              {cardData.nft.metadata.name}
            </div>
            <div className="text-[16px] text-[#999999]">
              1 of {cardData?.claim.maxQuantity}
            </div>
          </div>
          <Link to={`/${cardData.link}`}>
            <div className="bg-[#EBEBEB] p-4 w-full flex justify-center items-center">
              {mediaType === "image" && (
                <img
                  className="max-h-[400px]"
                  src={cardData.nft.metadata.image}
                  alt={cardData.nft.metadata.name}
                />
              )}
              {mediaType === "video" && (
                <video
                  autoPlay
                  playsInline
                  loop
                  muted
                  className="max-h-[400px]"
                >
                  <source src={cardData.nft.metadata.animation_url} />
                  Your browser can not play this video.
                </video>
              )}
            </div>
          </Link>
          <div className="bg-[#FAFAFA] p-4">
            <div className="grid grid-cols-2 mb-4">
              <div className="border-r-2 border-[#EBEBEB]">
                <div className="text-[#999999] text-[12px] mb-4">Price</div>
                <div className="flex flex-row items-baseline flex-wrap mb-4">
                  <span className="bold-header text-[24px]">
                    {`$${parseInt(
                      cardData?.claim?.currencyMetadata.displayValue
                    ).toFixed(2)}`}
                  </span>
                  <div className="flex flex-row ml-1">
                    <span className="bold-header text-[11px] leading-[1.25rem]">
                      USDC.e
                    </span>
                    <img
                      src={"/static/icons/usdc.png"}
                      alt={"logo"}
                      className="ml-1 h-4"
                    />
                  </div>
                </div>
                <div className="text-[#999999] text-[14px]">
                  {avaxPrice &&
                    `${(
                      cardData?.claim?.currencyMetadata.displayValue / avaxPrice
                    ).toFixed(4)} AVAX`}
                </div>
              </div>
              <div className="flex flex-row items-center justify-around">
                <img
                  src={"/static/icons/mastercard-icon.svg"}
                  alt={"logo"}
                  className="ml-1 h-[34px]"
                />
                <img
                  src={"/static/icons/amex-icon.svg"}
                  alt={"logo"}
                  className="ml-1 h-[34px]"
                />
                <img
                  src={"/static/icons/visa-icon.svg"}
                  alt={"logo"}
                  className="ml-1 h-[34px]"
                />
              </div>
            </div>
            {/* <button className="bg-[#6C00FF] py-2 px-4 rounded text-white w-full">
              Buy now
            </button> */}
            <PaperCheckout
              checkoutId={cardData.checkoutID}
              recipientWalletAddress={address}
            >
              <button
                type="button"
                className="bg-[#6C00FF] text-[14px] leading-[14px] py-[13px] px-4 rounded text-white w-full"
              >
                Buy now
              </button>
            </PaperCheckout>
          </div>
        </div>
      )}
    </>
  );
}
