import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function HomeTile({ data }) {
  const [mediaType, setMediaType] = useState("");

  useEffect(() => {
    const fileType = data.imageName.split(".")[1];
    if (
      fileType === "mp4" ||
      fileType === "MP4" ||
      fileType === "mov" ||
      fileType === "webm"
    ) {
      setMediaType("video");
    } else {
      setMediaType("image");
    }
  }, [data]);

  return (
    <Link to={`/${data.link}`}>
      <div className={`w-full flex justify-center items-center`}>
        {mediaType === "image" && (
          <img
            className="max-h-full"
            src={`/static/nftAssets/${data.imageName}`}
            alt={data.imageName}
          />
        )}
        {mediaType === "video" && (
          <video
            loading="lazy"
            autoPlay
            playsInline
            loop
            muted
            className="max-h-full"
          >
            <source src={`/static/nftAssets/${data.imageName}`} />
            Your browser can not play this video.
          </video>
        )}
      </div>
    </Link>
  );
}
