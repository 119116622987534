import "./App.css";
import { Routes, Route } from "react-router-dom";
import { useEditionDrop } from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { parse } from "papaparse";

//Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Details from "./components/Details";
import CompanyInfo from "./components/CompanyInfo";
import InvalidPage from "./components/InvalidPage";
import ScrollToTop from "./components/ScrollToTop";
import LoadingSpinner from "./components/LoadingSpinner";
import { NFTContextProvider } from "./contexts/NFTContext";

const dataFile = "/kuMarketpage.csv";

function App() {
  const [avaxPrice, setAvaxPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const drops = {
    //Sunna
    "0x74Cf9b474E86FD66c0256dfA1B0C257D25b64FC2": useEditionDrop(
      "0x74Cf9b474E86FD66c0256dfA1B0C257D25b64FC2"
    ),
    //Wang
    "0xb3caF325e40319b0c9a8dB9459649491db6Bd318": useEditionDrop(
      "0xb3caF325e40319b0c9a8dB9459649491db6Bd318"
    ),
    //Jones
    "0x643C40c5F73435d3Fc8B70F5fBb8bd358B313DEa": useEditionDrop(
      "0x643C40c5F73435d3Fc8B70F5fBb8bd358B313DEa"
    ),
    //Singh
    "0xDF3f6761a26b051D068f47A3d5d8FC5e8686302D": useEditionDrop(
      "0xDF3f6761a26b051D068f47A3d5d8FC5e8686302D"
    ),
    //Kubel
    "0x95e748Cf8cA8Ad66f3FDdb67cF0D9887D8014B7b": useEditionDrop(
      "0x95e748Cf8cA8Ad66f3FDdb67cF0D9887D8014B7b"
    ),
    //Anderson
    "0xD99CA4Da16275BeC9da8A85732899e074ebBDE10": useEditionDrop(
      "0xD99CA4Da16275BeC9da8A85732899e074ebBDE10"
    ),
    //Montague
    "0x15Ce19752225FE6042f64Fc9F296521ba5acb509": useEditionDrop(
      "0x15Ce19752225FE6042f64Fc9F296521ba5acb509"
    ),
    //Sommerville
    "0xf3b87A5dF17B2Ec8cADFf69B16C37fb46d7e5183": useEditionDrop(
      "0xf3b87A5dF17B2Ec8cADFf69B16C37fb46d7e5183"
    ),
    //Mutschelknaus
    "0x6688cbe4331ac8714DD4832f4f814b6767e7eaC6": useEditionDrop(
      "0x6688cbe4331ac8714DD4832f4f814b6767e7eaC6"
    ),
    //Gaur
    "0x4fc35898E76FE9ebe525e875D59193A9856B0871": useEditionDrop(
      "0x4fc35898E76FE9ebe525e875D59193A9856B0871"
    ),
    //Cottier
    "0x4D0c63d4182b82784e1682352378197E676a5652": useEditionDrop(
      "0x4D0c63d4182b82784e1682352378197E676a5652"
    ),
    //McDonagh
    "0x33CAaaa3c8B973c419e27861C5c9ef24b339C400": useEditionDrop(
      "0x33CAaaa3c8B973c419e27861C5c9ef24b339C400"
    ),
    //Dallyn
    "0x765Ce17Cf518B6c0D367c2e888fBEa0A6f0473F3": useEditionDrop(
      "0x765Ce17Cf518B6c0D367c2e888fBEa0A6f0473F3"
    ),
    //Irkhin
    "0x6B249E47Cbeb05E247397007b710aa4346C3D8d9": useEditionDrop(
      "0x6B249E47Cbeb05E247397007b710aa4346C3D8d9"
    ),
    //Vuong
    "0xef0CBaE44832eCbc964059a78e0875C62Bf6a1ad": useEditionDrop(
      "0xef0CBaE44832eCbc964059a78e0875C62Bf6a1ad"
    ),
    //Lawrence
    "0xb610B0069c29C8289c095c733CeD68DefDa7E646": useEditionDrop(
      "0xb610B0069c29C8289c095c733CeD68DefDa7E646"
    ),
    //Boghita
    "0xAF3Fe352d107D196380bB76Be6DA531822287a6B": useEditionDrop(
      "0xAF3Fe352d107D196380bB76Be6DA531822287a6B"
    ),
    //Zornic
    "0xdd37FC156177a16FCF3a38E9e7f2d4B3a9BDf346": useEditionDrop(
      "0xdd37FC156177a16FCF3a38E9e7f2d4B3a9BDf346"
    ),
    //Tyminski
    "0xCB48913903B01D701F50a55de9d291c5B10d4d94": useEditionDrop(
      "0xCB48913903B01D701F50a55de9d291c5B10d4d94"
    ),
    //Tinsley
    "0x8F7C5b4c00cfC1215F23fF6d6FF44bBd85Df8af1": useEditionDrop(
      "0x8F7C5b4c00cfC1215F23fF6d6FF44bBd85Df8af1"
    ),
    //Club
    "0xDb6972757189E0d324aDad2Bc6E9C1355F83EBB8": useEditionDrop(
      "0xDb6972757189E0d324aDad2Bc6E9C1355F83EBB8"
    ),
    //Calvert
    "0x2C30C5a651cCfe24e86F961E6F870e51272eC61F": useEditionDrop(
      "0x2C30C5a651cCfe24e86F961E6F870e51272eC61F"
    ),
    //Wyman
    "0x1a95C3c333F9E284BEC33959642307D53bcd53a8": useEditionDrop(
      "0x1a95C3c333F9E284BEC33959642307D53bcd53a8"
    ),
    //Mazali
    "0x73cf02B9ddEcE7De7E177a124EfB55d974720F80": useEditionDrop(
      "0x73cf02B9ddEcE7De7E177a124EfB55d974720F80"
    ),
    //Nix
    "0xCAf23D0cc81E4B44bCC421348C872609e075BFB4": useEditionDrop(
      "0xCAf23D0cc81E4B44bCC421348C872609e075BFB4"
    ),
    //Scher
    "0xB6Ec4c9Df4d0Bf301d281FD7a37cC72E562F9c45": useEditionDrop(
      "0xB6Ec4c9Df4d0Bf301d281FD7a37cC72E562F9c45"
    ),
  };

  useEffect(() => {
    axios
      .get(
        "https://min-api.cryptocompare.com/data/pricemultifull?fsyms=AVAX&tsyms=USD"
      )
      .then((res) => {
        setAvaxPrice(res.data?.RAW.AVAX.USD.PRICE);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    parse(dataFile, {
      download: true,
      header: true,
      complete: function (results) {
        const homePageData = {};
        for (const el of results.data) {
          if (el.position !== 0) {
            homePageData[el.position] = el;
          }
        }

        const detailsPageData = {};
        for (const el of results.data) {
          detailsPageData[el.link] = el;
        }

        setData({ rawData: results.data, homePageData, detailsPageData });
        setLoading(false);
      },
    });
  }, []);

  return (
    <div className="text-[#0E1231]">
      <NFTContextProvider>
        <ScrollToTop />
        <Navbar />
        {loading && (
          <div className="max-w-[1440px] mx-auto px-4 md:px-[88px]">
            <div className="flex justify-center items-center h-[600px]">
              <LoadingSpinner />
            </div>
          </div>
        )}
        {!loading && (
          <Routes>
            <Route
              path="/"
              element={<Home homePageData={data.homePageData} />}
            />
            <Route
              path="/:id"
              element={
                <Details
                  drops={drops}
                  avaxPrice={avaxPrice}
                  detailsPageData={data.detailsPageData}
                  rawData={data.rawData}
                />
              }
            />
            <Route
              path="/how-to"
              element={<CompanyInfo version={"how-to"} />}
            />
            <Route path="/about" element={<CompanyInfo version={"about"} />} />
            <Route path="/FAQs" element={<CompanyInfo version={"FAQs"} />} />
            <Route
              path="/terms-and-conditions"
              element={<CompanyInfo version={"terms-and-conditions"} />}
            />
            <Route
              path="/privacy-policy"
              element={<CompanyInfo version={"privacy-policy"} />}
            />
            <Route path="*" element={<InvalidPage />} />
          </Routes>
        )}
        <Footer />
      </NFTContextProvider>
    </div>
  );
}

export default App;
