import { Link } from "react-router-dom";
import HomeTile from "./HomeTile";

export default function Home({ homePageData }) {
  return (
    <>
      <div className="max-w-[1440px] mx-auto px-4 md:px-[88px]">
        <h1 className="bold-header text-center text-[26px] md:text-[68px] leading-none mt-[60px] md:mt-[120px]">
          Designed by Community
        </h1>
        <h1 className="hidden md:block bold-header text-center text-[26px] md:text-[68px] leading-none mb-[26px] md:mb-10">
          Helvetica The NFT digital art collection
        </h1>
        <h1 className="block md:hidden bold-header text-center text-[26px] md:text-[68px] leading-none">
          Helvetica The NFT digital
        </h1>
        <h1 className="block md:hidden bold-header text-center text-[26px] md:text-[68px] leading-none mb-[26px] md:mb-10">
          art collection
        </h1>
        <p className="hidden md:block text-center text-[#6E7183] text-[14px] leading-[24px] md:text-[20px] md:leading-none leading mb-10 md:mb-16">
          Monotype's first ever digital art collection in partnership with
          KnownUnknown.
        </p>
        <p className="block md:hidden text-center text-[#6E7183] text-[14px] md:text-[20px] leading-[24px] md:leading-none leading mb-0">
          Monotype's first ever digital art collection
        </p>
        <p className="block md:hidden text-center text-[#6E7183] text-[14px] md:text-[20px] leading-[24px] md:leading-none leading mb-10 md:mb-16">
          in partnership with KnownUnknown.
        </p>
        <img
          className="mx-auto mb-14 md:mb-20 h-7 md:h-14"
          src={"/static/monotype-logo.png"}
          alt={"logo"}
        />
        <div className="grid grid-cols-1 md:grid-cols-3 mx-auto">
          {homePageData[1] ? <HomeTile data={homePageData[1]} /> : <div></div>}
          {homePageData[2] ? <HomeTile data={homePageData[2]} /> : <div></div>}
          {homePageData[3] ? <HomeTile data={homePageData[3]} /> : <div></div>}
          {homePageData[4] ? <HomeTile data={homePageData[4]} /> : <div></div>}
          {homePageData[5] ? <HomeTile data={homePageData[5]} /> : <div></div>}
          {homePageData[6] ? <HomeTile data={homePageData[6]} /> : <div></div>}
          {homePageData[7] ? <HomeTile data={homePageData[7]} /> : <div></div>}
          {homePageData[8] ? <HomeTile data={homePageData[8]} /> : <div></div>}
          {homePageData[9] ? <HomeTile data={homePageData[9]} /> : <div></div>}
          {homePageData[10] ? (
            <HomeTile data={homePageData[10]} />
          ) : (
            <div></div>
          )}
          {homePageData[11] ? (
            <HomeTile data={homePageData[11]} />
          ) : (
            <div></div>
          )}
          {homePageData[12] ? (
            <HomeTile data={homePageData[12]} />
          ) : (
            <div></div>
          )}
        </div>
        <h2 className="bold-header text-center text-[26px] md:text-[68px] leading-none mt-16 md:mt-[120px] mb-6 md:mb-10">
          More than two dozen artists collaborate to create a unique NFT
          collection
        </h2>
        <p className="hidden md:block text-center text-[#6E7183] text-[14px] leading-[24px] md:text-[20px] md:leading-none mb-10 md:mb-[120px]">
          The collection explores several ideas and mediums, unique and personal
          to each artist.
        </p>
        <p className="block md:hidden text-center text-[#6E7183] text-[14px] leading-[24px] md:text-[20px] md:leading-none mb-0">
          The collection explores several ideas and mediums,
        </p>
        <p className="block md:hidden text-center text-[#6E7183] text-[14px] leading-[24px] md:text-[20px] md:leading-none mb-10 md:mb-[120px]">
          unique and personal to each artist.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 mx-auto mb-10 md:mb-20">
          {homePageData[13] ? (
            <HomeTile data={homePageData[13]} />
          ) : (
            <div></div>
          )}
          <a
            rel="noreferrer"
            target="_blank"
            href={"https://go.kalao.io/helveticathenft/"}
          >
            <video
              loading="lazy"
              autoPlay
              playsInline
              loop
              muted
              className="max-h-full"
            >
              <source src={`/static/nftAssets/HHH_IceCube_Icon.mp4`} />
              Your browser can not play this video.
            </video>
          </a>
          {homePageData[15] ? (
            <HomeTile data={homePageData[15]} />
          ) : (
            <div></div>
          )}
          {homePageData[16] ? (
            <HomeTile data={homePageData[16]} />
          ) : (
            <div></div>
          )}
          {homePageData[17] ? (
            <HomeTile data={homePageData[17]} />
          ) : (
            <div></div>
          )}
          {homePageData[18] ? (
            <HomeTile data={homePageData[18]} />
          ) : (
            <div></div>
          )}
          {/* </div>
        <img
          className="mx-auto max-h-7 md:max-h-[90px] mt-10 md:mt-[120px] mb-4"
          src={"/static/partners.png"}
          alt={"logo"}
        />
        <p className="hidden md:block text-center text-[#6E7183] text-[14px] leading-[24px] md:text-[20px] md:leading-none mb-10 md:mb-[120px]">
          A unique collaboration with platforms and brands seeking to make a
          difference.
        </p>
        <p className="block md:hidden text-center text-[#6E7183] text-[14px] leading-[24px] md:text-[20px] md:leading-none mb-0">
          A unique collaboration with platforms and brands
        </p>
        <p className="block md:hidden text-center text-[#6E7183] text-[14px] leading-[24px] md:text-[20px] md:leading-none mb-10 md:mb-[120px]">
          seeking to make a difference.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 mx-auto mb-10 md:mb-20"> */}
          {homePageData[19] ? (
            <HomeTile data={homePageData[19]} />
          ) : (
            <div></div>
          )}
          {homePageData[20] ? (
            <HomeTile data={homePageData[20]} />
          ) : (
            <div></div>
          )}
          {homePageData[21] ? (
            <HomeTile data={homePageData[21]} />
          ) : (
            <div></div>
          )}
          {homePageData[22] ? (
            <HomeTile data={homePageData[22]} />
          ) : (
            <div></div>
          )}
          {homePageData[23] ? (
            <HomeTile data={homePageData[23]} />
          ) : (
            <div></div>
          )}
          {homePageData[24] ? (
            <HomeTile data={homePageData[24]} />
          ) : (
            <div></div>
          )}
          {homePageData[25] ? (
            <HomeTile data={homePageData[25]} />
          ) : (
            <div></div>
          )}
          {homePageData[26] ? (
            <HomeTile data={homePageData[26]} />
          ) : (
            <div></div>
          )}
          {homePageData[27] ? (
            <HomeTile data={homePageData[27]} />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}
